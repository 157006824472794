
@font-face {
  font-family: "lores-12";
  src: url("https://use.typekit.net/af/84d9ce/00000000000000007735bf82/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/84d9ce/00000000000000007735bf82/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/84d9ce/00000000000000007735bf82/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
	font-style: normal;
	font-weight: 400;
  font-display: swap;
  unicode-range: U+0020-U007F;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('assets/fonts/MonumentExtended-Black1.woff2') format('woff2'),
    url('assets/fonts/MonumentExtended-Black.woff') format('woff'),
    url('assets/fonts/MonumentExtended-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Amina';
  src: url('assets/fonts/Amina-Regular1.woff2') format('woff2'),
    url('assets/fonts/Amina-Regular.woff') format('woff'),
    url('assets/fonts/Amina-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('assets/fonts/MonumentExtended-Regular1.woff2') format('woff2'),
    url('assets/fonts/MonumentExtended-Regular.woff') format('woff'),
    url('assets/fonts/MonumentExtended-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Amina';
  src: url('assets/fonts/Amina-Bold1.woff2') format('woff2'),
    url('assets/fonts/Amina-Bold.woff') format('woff'),
    url('assets/fonts/Amina-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0020-007F;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  touch-action:none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

main {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
/* *:not(html) {
	-webkit-transform: translate3d(0, 0, 0);
} */